.wrap-barLoader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.barLoader-box {
    background-color: none;
    overflow: hidden;
    width: 100px;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader{
    width: 100px;
    height: 1px;
    background-color: white;
    animation: animBar 2s cubic-bezier(0.215, 0.610, 0.355, 1) infinite ;
}

@keyframes animBar{
    from { transform: translateX(-100%); }
    50% { transform: translateX(0%); }
    to { transform: translateX(105%);}
}