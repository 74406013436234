
/*============= Couleurs =============*/
:root {
  --darkGrey: #262A2F;
  --lightGrey: #C6CACF;

  --bg: ;
  --bg-accent: ;
  --text-color: ;

  --border: 1px solid var(--darkGrey);
  --border-radius: 8px;
  --speedFast: 500ms;
  --speedMid: 1000ms;
  --speedLow: 1500ms;

  --sizeTitle: 2.2rem;
  --size2: 1.6rem;
  --size3: 1.2rem;
  --size4: 1.08rem;
  --size5: .8rem;


}

html, body {
    margin: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* width: 100%;
    height: 100vh; */
    font-family: 'Sarabun', sans-serif;
    background-color: #161A1F;
    color: var(--lightGrey);
    letter-spacing: .5px;
    font-weight: 200;

}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.conteneurPage {
  padding: 1rem;
  overflow: auto;
  width: 100%;
}


.titreProjet {  
  font-size: var(--sizeTitle);
  width: 87%;
  font-weight: 400;
}

.texte {
  /* text-align: justify; */
  font-size: var(--size4);
  /* margin: initial; */
  line-height: 1.6rem;
  /* color: white; */
  text-decoration: none;
}

.infoProjet {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-content: center;
  justify-content: center;
  /* justify-items: center; */
  
}


@media (min-width: 551px ) {  
  .conteneurPage {
      padding: 2rem;
  }


}

@media (min-width: 769px ) and (min-height: 551px) {          
  .conteneurPage {
      padding: 2rem;
      padding-left: 20rem;
  }


  .titreProjet {  
      width: 100%;
  }
}

@media (min-width: 1280px ) {  
  .infoProjet {
    grid-template-columns: 80%;
  }

  .conteneurPage {
      padding: 4rem;
      padding-left: 24rem;
      padding-top: 2rem;
  }
      
  
  
}
